import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "tab", "content", 'input', 'telInput', 'nextBtn', 'orderItem' ,
    'orderContent', 'orderAmount']
  static values = {
    lastValidStep: Number,
    currentStep: Number
  }

  connect() {
    this.element.controller = this;
    this.refresh();
    this.inputTargets.forEach(input => {
      input.addEventListener('keydown', (e) => {
        if (e.keyCode == 13) {
          e.preventDefault();
          return false;
        }
      })
    })
  }

  get currentStep() {
    return parseInt(this.tabTargets.find(tab => tab.classList.contains("active")).dataset.step);
  }

  stepInputs(step) {
    return this.inputTargets.filter(input => input.dataset.stepValidation == step);
  }

  stepIsValidated(step) {
    if (step == 1) {
      return (this.stepInputs(1).every(input => input.value) && (this.telInputTarget.dataset.valid == 'true'));
    }
    if ([2,3].includes(step)) return this.stepInputs(2).some(input => input.value > 0);
    return false;
  }

  refresh() {
    this.tabTargets.forEach(tab => tab.classList.remove("active"));
    this.contentTargets.forEach(content => content.classList.add("hidden"));
    const currentTab = this.tabTargets.find(tab => tab.dataset.step == this.currentStepValue);
    if (currentTab) currentTab.classList.add('active');
    const currentContent = this.contentTargets.find(content => content.dataset.step == this.currentStepValue);
    if (currentContent) currentContent.classList.remove('hidden');
  }

  switch() {
    const askedStep = event.currentTarget.dataset.step;
    if (!(askedStep <= this.lastValidStepValue + 1)) return;
    this.currentStepValue = askedStep;
    this.refresh();
  }

  refreshLastValidStep() {
    this.lastValidStepValue = 0;
    this.lastValidStepValue = [1, 2, 3, 4].find(step => !this.stepIsValidated(step)) - 1;
    const validTabs = this.tabTargets.filter(tab => tab.dataset.step <= this.lastValidStepValue);
    validTabs.forEach(tab => tab.classList.add("validated"))
  }

  checkTelFormat() {
    this.telInputTarget.style.color = 'black';
    const regex = /^((\+33((\s*)|-|.))|0)\d{1}(((\s*)|-|.)\d{2}((\s*)|-|.)){4}$/;
    event.currentTarget.dataset.valid = regex.test(event.currentTarget.value);
  }

  telFormating() {
    this.telInputTarget.style.color = this.telInputTarget.dataset.valid != 'true' ? 'red' : 'black'
  }

  validate() {
    const countsFor = parseInt(event.currentTarget.dataset.stepValidation);
    const nextBtn = this.nextBtnTargets.find(target => target.dataset.step == countsFor);
    if (this.stepIsValidated(countsFor)) {
      if (nextBtn) nextBtn.classList.remove('hidden');
    } else {
      if (nextBtn) nextBtn.classList.add('hidden');
    };
    this.refreshLastValidStep();
    this.refreshRecap();
  }

  goToStep2() { this.goToStep(2) }
  goToStep3() { this.goToStep(3) }
  goToStep4() { this.goToStep(4) }

  goToStep(step) {
    event.preventDefault();
    this.tabTargets.find(tab => tab.dataset.step == step).click();
  }

  refreshRecap() {
    const orderContentHTML = this.orderItems.map(orderItem => {
      return `
        <div class="c-order-item-recap">
          <span>${this.emoji(orderItem.category)} ${orderItem.name} - ${orderItem.price}€</span>
          <i class="fas fa-arrow-right"></i>
          <span>x${orderItem.quantity}</span>
        </div>
      `
    }).join('');
    this.orderContentTarget.innerHTML = orderContentHTML;
    const orderAmount = this.orderItems.map(item => parseFloat(item.price) * item.quantity).reduce((a, b) => a + b, 0);
    this.orderAmountTarget.innerText = `${orderAmount}€`;
  }

  get orderItems() {
    const inputs = this.orderItemTargets.filter(orderItem => parseInt(orderItem.value) > 0);
    return inputs.map((input) => {
      return {
        name: input.dataset.name,
        price: input.dataset.price,
        quantity: parseInt(input.value),
        category: input.dataset.category
      }
    });
  }

  emoji(orderItemCategory) {
    if (['entree', 'plat'].includes(orderItemCategory)) return "🍲";
    if (orderItemCategory == "fromage") return "🧀";
    if (orderItemCategory == "dessert") return "🍨";
    if (orderItemCategory == "wine") return "🍷";
    return '';
  }

}
