import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  increment() {
    this.inputTarget.value = parseInt(this.inputTarget.value) + 1;
    this.triggerChangeEvent(this.inputTarget);
  }

  decrement() {
    if (parseInt(this.inputTarget.value) < 1) return;
    this.inputTarget.value = parseInt(this.inputTarget.value) - 1;
    this.triggerChangeEvent(this.inputTarget);
  }

  triggerChangeEvent(element) {
    const event = new Event('change');
    element.dispatchEvent(event);
  }
}
