import { Controller } from "stimulus";
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"

export default class extends Controller {
  connect() {
    flatpickr(this.element, {
      altInput: true,
      altFormat: "j F Y",
      dateFormat: "Y-m-d",
      locale: French,
    });
  }
}
