import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "datesContainer", "slot" ]

  connect() {
    this.currentDateValue = new Date();
    this.datesContainerTarget.innerHTML = this.slotsHtml();
  }

  get orderSlotRecap() {
    return document.getElementById('order-slot');
  }

  select() {
    this.inputTarget.value = event.currentTarget.dataset.timeSlot;
    this.orderSlotRecap.innerText = event.currentTarget.innerText;
    const change = new Event('change');
    this.inputTarget.dispatchEvent(change);
    this.slotTargets.forEach(slot => slot.classList.remove('selected'));
    event.currentTarget.classList.add('selected');
  }

  get currentDayShort() {
    return `${this.currentDateValue.getMonth() + 1}/${this.currentDateValue.getDate()}/${this.currentDateValue.getFullYear()}`
  }

  get lunchSlots() {
    const times = ["12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00"];
    const dateTimes = times.map(time => new Date(`${this.currentDayShort} ${time}`));
    return dateTimes.filter(dateTime => (dateTime - this.currentDateValue) > (29 * 60000))
  }

  get dinerSlots() {
    const times = ["19:30", "19:45", "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45", "22:00"];
    const dateTimes = times.map(time => new Date(`${this.currentDayShort} ${time}`));
    return dateTimes.filter(dateTime => (dateTime - this.currentDateValue) > (29 * 60000))
  }

  get availableSlots() {
    if (this.currentDateValue < new Date(`${this.currentDayShort} 13:30`)) return this.lunchSlots;
    if (this.currentDateValue < new Date(`${this.currentDayShort} 21:30`)) return this.dinerSlots;
  }

  slotHtml(timeSlot) {
    return `
      <div class="order-slot" data-time-slot="${timeSlot.toString()}" data-action="click->order-slots#select" data-order-slots-target="slot">
        ${timeSlot.getHours()}h${timeSlot.getMinutes() > 0 ? timeSlot.getMinutes() : ''}
      </div>
    `
  }


  slotsHtml() {
    return this.availableSlots.map(timeSlot => this.slotHtml(timeSlot)).join('')
  }
}
